import { ReactNode } from "react"
import { createRoot } from "react-dom/client"

import { Component } from "@inertiajs/core"
import { createInertiaApp } from "@inertiajs/react"

import "./bootstrap"

import AuthLayout from "@/Layouts/AuthLayout"
import DesignSystemLayout from "@/Layouts/DesignSystemLayout"
import GenericLayout from "@/Layouts/GenericLayout"
import HomebuyerPathLayout from "@/Layouts/HomebuyerPathLayout"
import InvitationLayout from "@/Layouts/InvitationLayout"
import PartnerLayout from "@/Layouts/PartnerLayout"
import UserExperienceLayout from "@/Layouts/UserExperienceLayout"
import VisitorLayout from "@/Layouts/VisitorLayout"
import WhrcAdminLayout from "@/Layouts/WhrcAdminLayout"

const appName =
  window.document.getElementsByTagName("title")[0]?.innerText ||
  "Homeownership Portal"

interface LayoutComponent {
  (page: ReactNode): Component
}
interface LayoutableComponent {
  layout?: LayoutComponent
}

void createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => {
    const pages = import.meta.glob<LayoutableComponent>("./Pages/**/*.tsx", {
      import: "default",
    })
    const pageAssetName = `./Pages/${name}.tsx`
    const finalPageAssetName = pages[pageAssetName]
      ? pageAssetName
      : "./Pages/NotFound.tsx"
    const page = pages[finalPageAssetName]()

    return page.then((page) => {
      if (page.layout) {
        return page
      }

      if (name.startsWith("Auth")) {
        page.layout = (page) => <AuthLayout>{page}</AuthLayout>
      } else if (name.startsWith("Client/HomebuyerPath")) {
        page.layout = (page) => (
          <HomebuyerPathLayout kind={"homebuyer"}>{page}</HomebuyerPathLayout>
        )
      } else if (name.startsWith("Client")) {
        page.layout = (page) => <VisitorLayout>{page}</VisitorLayout>
      } else if (name.startsWith("Visitor")) {
        page.layout = (page) => <VisitorLayout>{page}</VisitorLayout>
      } else if (name.startsWith("Invitation")) {
        page.layout = (page) => <InvitationLayout>{page}</InvitationLayout>
      } else if (name.startsWith("Partner")) {
        page.layout = (page) => <PartnerLayout>{page}</PartnerLayout>
      } else if (name.startsWith("WhrcAdmin")) {
        page.layout = (page) => <WhrcAdminLayout>{page}</WhrcAdminLayout>
      } else if (name.startsWith("_UserExperience")) {
        page.layout = (page) => (
          <UserExperienceLayout>{page}</UserExperienceLayout>
        )
      } else if (name.startsWith("_Private")) {
        page.layout = (page) => <DesignSystemLayout>{page}</DesignSystemLayout>
      } else {
        page.layout = (page) => <GenericLayout>{page}</GenericLayout>
      }

      return Promise.resolve(page)
    })
  },
  setup({ el, App, props }) {
    const root = createRoot(el)

    root.render(<App {...props} />)
  },
  progress: {
    color: "#4B5563",
  },
})
