import { PropsWithChildren } from "react"

import ClientFooterContainer from "@/Components/Containers/ClientFooterContainer"

export default function PublicFooter({ children }: PropsWithChildren) {
  return (
    <>
      <div className="bg-base-black">
        <ClientFooterContainer>
          <section className="flex justify-center py-4 sm:py-6" role="banner">
            <div className="space-x-1 text-center text-xs font-semibold text-white">
              <span className="block sm:inline-block">©2023</span>
              <span className="block sm:inline-block">
                Washington Homeownership Resource Center.
              </span>
              <span className="block sm:inline-block">
                All rights reserved.
              </span>
            </div>
          </section>
        </ClientFooterContainer>
      </div>
      {children}
    </>
  )
}
