import { PropsWithChildren } from "react"

import { Link as InertiaLink, usePage } from "@inertiajs/react"

import { Item } from "./types"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}
const Link = ({ item }: PropsWithChildren<{ item: Item }>) => {
  const { url } = usePage()

  const current = new URL(url, window.location.origin).href.startsWith(
    item.href,
  )

  const Icon = item.icon

  return (
    <InertiaLink
      key={item.name}
      href={item.href}
      className={classNames(
        current
          ? "cursor-default border-cerulean-600 text-cerulean-600"
          : "border-transparent text-gray-600 hover:border-gray-500 hover:text-gray-900",
        "group flex items-center border-l-4 py-3 px-3 text-sm font-medium",
      )}
      aria-current={current ? "page" : undefined}
    >
      {Icon && (
        <Icon
          className={classNames(
            current
              ? "text-cerulean-500"
              : "text-gray-400 group-hover:text-gray-500",
            "mr-3 h-6 w-6 flex-shrink-0",
          )}
          aria-hidden="true"
        />
      )}
      <span className="truncate">{item.name}</span>
      {item.count ? (
        <span
          className={classNames(
            current ? "bg-gray-200" : "bg-gray-200 text-gray-600",
            "ml-auto inline-block rounded-full py-0.5 px-3 text-xs",
          )}
        >
          {item.count}
        </span>
      ) : null}
    </InertiaLink>
  )
}

export default Link
