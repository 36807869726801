import React from "react"

import { AnyProfile } from "@/types"

import classNames from "@/util/classNames"
import { generateAvatarUrlForAnyProfile } from "@/util/generateAvatarUrls"

import {
  ProfileForPresentation,
  getProfileForPresentation,
} from "@/packages/getProfileForPresentation"

interface Props {
  alt?: string | undefined
  className?: string
  profile: ProfileForPresentation | AnyProfile
}
export default function SimpleAvatar({ profile, alt, className }: Props) {
  return (
    <img
      className={classNames(
        className,
        "flex-none rounded-full bg-gray-50 object-cover",
      )}
      src={
        "profileKind" in profile
          ? profile.avatarUrl
          : generateAvatarUrlForAnyProfile(profile)
      }
      alt={alt || ("name" in profile ? profile.name : generateName(profile))}
    />
  )
}

function generateName(profile: AnyProfile) {
  if ("firstName" in profile) {
    return profile.firstName + " " + profile.lastName
  } else {
    return profile.name
  }
}
