import { PropsWithChildren } from "react"

import MinimalLayout from "@/Layouts/MinimalLayout"

import ClientContentContainer from "@/Components/Containers/ClientContentContainer"
import NarrowContainer from "@/Components/Containers/NarrowContainer"
import SettingsSectionFullWidthContent from "@/Components/Settings/SettingsSectionFullWidthContent"

export const InvitationLayout = ({ children }: PropsWithChildren) => {
  return (
    <MinimalLayout>
      <ClientContentContainer>{children}</ClientContentContainer>
    </MinimalLayout>
  )
}

export default InvitationLayout
