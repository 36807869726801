import { RefObject, createContext } from "react"

type ValidateActionType = "submit" | "jump"

interface ValidateAction {
  type: ValidateActionType
}

interface SubmitValidateAction extends ValidateAction {
  type: "submit"
}

interface JumpValidateAction extends ValidateAction {
  type: "jump"
}

const submitValidators: { [id: string]: Validator | undefined } = {}

const state = {
  focusAlreadyTriggered: false,
}

const triggerFocus = (ref: RefObject<HTMLInputElement>): void => {
  if (state.focusAlreadyTriggered) {
    return
  }

  if (!ref.current) {
    return
  }

  ref.current.focus()

  state.focusAlreadyTriggered = true
}

const addSubmitValidator = (
  id: string,
  validator: Validator,
): CleanupHandler => {
  submitValidators[id] = validator

  return () => {
    submitValidators[id] = undefined
  }
}

const jumpValidators: { [id: string]: Validator | undefined } = {}

const addJumpValidator = (id: string, validator: Validator): CleanupHandler => {
  jumpValidators[id] = validator

  return () => {
    jumpValidators[id] = undefined
  }
}

const validationFailedHandlers: {
  [id: string]: ValidationFailedHandler | undefined
} = {}

const addValidationFailedHandler = (
  id: string,
  validationFailedHandler: ValidationFailedHandler,
): CleanupHandler => {
  validationFailedHandlers[id] = validationFailedHandler

  return () => {
    validationFailedHandlers[id] = undefined
  }
}

const validateJump = (sourceId?: string | undefined): boolean => {
  const isValid = Object.keys(jumpValidators).reduce((acc, id) => {
    const validator = jumpValidators[id]

    state.focusAlreadyTriggered = false

    if (validator && !validator()) {
      acc = false
    }

    return acc
  }, true)

  if (!isValid) {
    triggerValidationFailedHandlers(sourceId)
  }

  return isValid
}

const validateSubmit = (sourceId?: string | undefined): boolean => {
  const isValid = Object.keys(submitValidators).reduce((acc, id) => {
    const validator = submitValidators[id]

    state.focusAlreadyTriggered = false

    if (validator && !validator()) {
      acc = false
    }

    return acc
  }, true)

  if (!isValid) {
    triggerValidationFailedHandlers(sourceId)
  }

  return isValid
}

const triggerValidationFailedHandlers = (
  sourceId?: string | undefined,
): void => {
  Object.values(validationFailedHandlers).forEach((validationFailedHandler) => {
    if (!validationFailedHandler) {
      return
    }

    validationFailedHandler(sourceId)
  })
}

// Return true if validation was successful
type Validator = () => boolean

type ValidationFailedHandler = (sourceId: string | undefined) => void

type CleanupHandler = () => void

let counter = 0

const DatumQuestionValidation = createContext({
  generateIdentifier: () => `validator-id-${counter++}`,
  addSubmitValidator,
  addJumpValidator,
  addValidationFailedHandler,
  validateJump,
  validateSubmit,
  triggerValidationFailedHandlers,
  triggerFocus,
})

export default DatumQuestionValidation
