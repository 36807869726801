import { PropsWithChildren } from "react"

import MinimalLayout from "@/Layouts/MinimalLayout"

interface OnboardingLayoutProps {
  kind: "homebuyer"
}
export const HomebuyerPathLayout = ({
  children,
}: PropsWithChildren<OnboardingLayoutProps>) => {
  return (
    <MinimalLayout>
      <div className="w-full bg-slate-100 bg-buy-a-home-onboarding bg-center xl:bg-50%">
        {children}
      </div>
    </MinimalLayout>
  )
}

export default HomebuyerPathLayout
