import { PropsWithChildren } from "react"
import route from "ziggy-js"

import {
  ChartBarIcon,
  CogIcon,
  EnvelopeIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import { usePage } from "@inertiajs/react"

import { PageProps } from "@/types"

import NonClientShell from "@/Components/Shells/NonClient/NonClientShell"

const PartnerLayout = ({ children }: PropsWithChildren) => {
  const {
    auth: { effectivePartner },
  } = usePage<PageProps>().props

  const navigation = [
    {
      name: "Dashboard",
      href: route("partner.dashboard", {
        partner: effectivePartner.partner.slug,
      }),
      icon: HomeIcon,
    },
    {
      name: "Requests",
      href: route("partner.requests.index", {
        partner: effectivePartner.partner.slug,
      }),
      icon: InboxIcon,
    },
    {
      name: "Messages",
      href: route("partner.messages.index", {
        partner: effectivePartner.partner.slug,
      }),
      icon: EnvelopeIcon,
      // count: 5,
    },
    {
      name: "Clients",
      href: route("partner.clients.index", {
        partner: effectivePartner.partner.slug,
      }),
      icon: UsersIcon,
    },
    {
      name: "Reports",
      href: route("partner.reports.index", {
        partner: effectivePartner.partner.slug,
      }),
      icon: ChartBarIcon,
    },
    {
      name: "Settings",
      href: route("partner.settings.index", {
        partner: effectivePartner.partner.slug,
      }),
      icon: CogIcon,
    },
  ]

  return (
    <NonClientShell
      sectionTitle="Partner Portal"
      sectionSubtitle={effectivePartner.partner.name}
      navigation={navigation}
    >
      {children}
    </NonClientShell>
  )
}

export default PartnerLayout
