import { PropsWithChildren } from "react"
import route from "ziggy-js"

import {
  BuildingOffice2Icon,
  ChartBarIcon,
  EnvelopeIcon,
  HomeIcon,
  InboxIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import NonClientShell from "@/Components/Shells/NonClient/NonClientShell"

const navigation = [
  { name: "Dashboard", href: route("whrc-admin.dashboard"), icon: HomeIcon },
  {
    name: "Requests",
    href: route("whrc-admin.requests.index"),
    icon: InboxIcon,
  },
  {
    name: "Messages",
    href: route("whrc-admin.messages.index"),
    icon: EnvelopeIcon,
  },
  { name: "Clients", href: route("whrc-admin.clients.index"), icon: UsersIcon },
  {
    name: "Partners",
    href: route("whrc-admin.partners.index"),
    icon: BuildingOffice2Icon,
  },
  { name: "Users", href: route("whrc-admin.users.index"), icon: UserIcon },
  {
    name: "Reports",
    href: route("whrc-admin.reports.index"),
    icon: ChartBarIcon,
  },
]

const WhrcAdminLayout = ({ children }: PropsWithChildren) => {
  return (
    <NonClientShell sectionTitle="WHRC Admin" navigation={navigation}>
      {children}
    </NonClientShell>
  )
}
export default WhrcAdminLayout
