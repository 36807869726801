import SidebarNav, { Item } from "@/Components/Navigation/SidebarNav"

const MobileMenu = ({ navigation }: { navigation: Array<Item> }) => {
  return (
    <>
      <div className="w-full md:hidden">
        <SidebarNav.Navbar>
          {navigation.map((item) => (
            <SidebarNav.Link key={item.name} item={item} />
          ))}
        </SidebarNav.Navbar>
      </div>
    </>
  )
}
export default MobileMenu
