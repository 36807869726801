import { PropsWithChildren } from "react"

import { Link, usePage } from "@inertiajs/react"

import { PageProps } from "@/types"

import WhiteOutlinedButton from "@/Components/Buttons/WhiteOutlinedButton"
import ClientHeaderContainer from "@/Components/Containers/ClientHeaderContainer"
import Dropdown from "@/Components/Dropdown"
import WhrcLogoOnDark from "@/Components/Logos/WhrcLogoOnDark"

export default function PublicHeader({ children }: PropsWithChildren) {
  const { auth } = usePage<PageProps>().props

  return (
    <>
      <div className="bg-indigo-950">
        <ClientHeaderContainer notTight>
          <section
            className="flex justify-between space-x-8 py-2 sm:py-3 lg:py-4"
            role="banner"
          >
            <div>
              <Link href="/">
                <WhrcLogoOnDark className={"h-full"} />
              </Link>
            </div>
            <div></div>
            {(auth.user && (
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <div className="relative ml-3">
                  <Dropdown>
                    <Dropdown.Trigger>
                      <button
                        type="button"
                        className="inline-flex items-center font-normal text-gray-100 py-[7px]"
                      >
                        <span>
                          {auth.user.first_name} {auth.user.last_name}
                        </span>
                        <svg
                          className="ml-2 -mr-0.5 h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </Dropdown.Trigger>

                    <Dropdown.Content>
                      <Dropdown.Link href={route("profile.edit")}>
                        Profile
                      </Dropdown.Link>
                      <Dropdown.Link
                        href={route("logout")}
                        method="post"
                        as="button"
                      >
                        Log Out
                      </Dropdown.Link>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
              </div>
            )) || (
              <div className="space-x-2 sm:space-x-4">
                <label htmlFor="signIn" className="font-normal text-gray-100">
                  <span className="hidden text-sm md:inline">
                    Already have an account?
                  </span>
                  <span className="hidden text-xs sm:inline md:hidden">
                    Have an account?
                  </span>
                </label>
                <WhiteOutlinedButton id="signIn" href={route("login")}>
                  Sign in
                </WhiteOutlinedButton>
              </div>
            )}
          </section>
        </ClientHeaderContainer>
      </div>
      {children}
    </>
  )
}
