import { PropsWithChildren } from "react"

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  tight?: boolean
}

const NarrowContainerContainer = ({
  children,
  className,
  tight = false,
}: PropsWithChildren<Props & { className?: string }>) => {
  const baseClassName = "m-0 mx-auto max-w-full sm:max-w-md"
  const tightClassName = tight ? "p-0" : "p-4 sm:p-6 xl:p-8"

  return (
    <div className={classNames(baseClassName, tightClassName, className)}>
      {children}
    </div>
  )
}

export default NarrowContainerContainer
