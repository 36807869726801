import Link from "./Link"
import Navbar from "./Navbar"
import { Item } from "./types"

const SidebarNav = {
  Navbar,
  Link,
}
export default SidebarNav

export { Navbar, Link }
export type { Item }
