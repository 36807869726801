import { PropsWithChildren } from "react"

import MinimalLayout from "@/Layouts/MinimalLayout"

import ClientContentContainer from "@/Components/Containers/ClientContentContainer"

export default function GenericLayout({ children }: PropsWithChildren) {
  return (
    <MinimalLayout>
      <ClientContentContainer>{children}</ClientContentContainer>
    </MinimalLayout>
  )
}
