const MobileSearch = () => {
  return (
    <>
      <div className="flex justify-between p-2 md:hidden">
        <input type="text" />
        <input type="submit" value="Search" />
      </div>
    </>
  )
}

export default MobileSearch
