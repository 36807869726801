import { PropsWithChildren } from "react"

import PublicFooter from "@/Layouts/Common/PublicFooter"
import PublicHeader from "@/Layouts/Common/PublicHeader"

export default function FillVerticalSpaceContainer({
  children,
}: PropsWithChildren) {
  return (
    <div className="flex min-h-[calc(100vh-72px-80px)] w-full items-stretch sm:min-h-[calc(100vh-78px-64px)] lg:min-h-[calc(100vh-86px-64px)]">
      {children}
    </div>
  )
}
