import { SVGProps, memo } from "react"

import { HomebuyerPathIcon } from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const WhrcLogomarkOnLightComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 138 99.7"
    {...props}
  >
    <path
      d="m27.5 75 1.8 21.7c-2.4.2-4.7.3-7 .5L27.5 75zm78.8 21.6c2.4.2 4.7.3 7 .5l-5.1-22-1.9 21.5zm-38.5-80c-6.2 6.8-23.1 24.6-32.2 33.2l.7 46.5c10.4-.6 21.3-.9 32.7-.9 10.5 0 20.6.3 30.3.8l.7-46.9c-9.1-8.5-26-25.9-32.2-32.7zm70.2.7v82.5c-5.6-.8-11.5-1.5-17.7-2.1l-4.9-38.3V59c5.1 3 10.7 6.4 15.8 9.1v-4.9L67.8 10.9 4.4 63.2v4.9c5.1-2.7 10.7-5.9 15.8-8.8v.1l-4.9 38.5C10 98.4 4.9 99.1 0 99.7V17.3C0 7.7 7.7 0 17.3 0h103.5c9.5 0 17.2 7.7 17.2 17.3zM28.8 26.5c-3.2-.7-6.7 1.9-7.7 5.9s.7 7.9 3.9 8.6 6.7-1.9 7.7-5.9c1.1-4-.6-7.8-3.9-8.6zm85.7 5.9c-1-4-4.5-6.6-7.7-5.9-3.2.7-5 4.6-3.9 8.6 1 4 4.5 6.6 7.7 5.9 3.2-.7 5-4.5 3.9-8.6z"
      style={{
        fill: "#007ba6",
      }}
    />
  </svg>
)

export const WhrcLogomarkOnLight = memo(WhrcLogomarkOnLightComponent)

export default WhrcLogomarkOnLight
