import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react"

import { InertiaLinkProps } from "@inertiajs/react"

export interface BasicButtonProps {
  actualAs?: ButtonAs
  asLink?: boolean
  impact?: ButtonImpact
  isDisabled?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>,
  ) => boolean | void
  isFauxDisabled?: boolean
  onClickWhenDisabled?: () => void
  onDisabledEffectComplete?: () => void
  size?: ButtonSize
  variant?: ButtonVariant
  withoutInertia?: boolean
}

type HtmlButtonProps =
  | ButtonHTMLAttributes<HTMLOrSVGElement>
  | AnchorHTMLAttributes<HTMLOrSVGElement>
export type ButtonProps = BasicButtonProps &
  (InertiaLinkProps | HtmlButtonProps)

export enum ButtonAs {
  button,
  link,
}
export enum ButtonSize {
  xs,
  sm,
  base,
  lg,
  xl,
}

export enum ButtonVariant {
  primary,
  secondary,
  white,
  link,
  whiteOutlined,
  mediumImpact,
  severeImpact,
}

export enum ButtonImpact {
  normal,
  medium,
  severe,
}
