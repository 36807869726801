import { MouseEventHandler } from "react"

import { Link, usePage } from "@inertiajs/react"

import { PageProps } from "@/types"

import SimpleAvatar from "@/Components/Avatars/SimpleAvatar"
import Dropdown from "@/Components/Dropdown"
import WhrcLogoOnLight from "@/Components/Logos/WhrcLogoOnLight"
import WhrcLogomarkOnLight from "@/Components/Logos/WhrcLogomarkOnLight"

interface PrimaryHeaderProps {
  isMobileMenuOpen?: boolean
  isMobileSearchOpen?: boolean
  onMobileMenuClick?: () => void
  onMobileSearchClick?: () => void
  sectionSubtitle?: string
  sectionTitle: string
}

const PrimaryHeader = (props: PrimaryHeaderProps) => {
  const { auth } = usePage<PageProps>().props

  const mobileSearchClickHandler: MouseEventHandler<HTMLAnchorElement> = (
    e,
  ) => {
    e.preventDefault()

    if (!props.onMobileSearchClick) {
      return
    }

    props.onMobileSearchClick()
  }

  const mobileMenuClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()

    if (!props.onMobileMenuClick) {
      return
    }

    props.onMobileMenuClick()
  }

  return (
    <div className="flex h-16 items-center">
      <div className="ml-2 flex flex-initial md:hidden">
        <a
          className="cursor-pointer p-1 text-cerulean-500 hover:text-cerulean-600"
          onClick={mobileMenuClickHandler}
        >
          {!props.isMobileMenuOpen && (
            <svg
              fill="none"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-8 w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}

          {props.isMobileMenuOpen && (
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-8 w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </a>
      </div>
      <div className="mr-0 flex h-16 grow md:bg-charcoal-50 md:pl-2 align-middle md:w-48 md:grow-0 lg:w-64">
        <div className="flex w-full justify-between pr-2">
          <Link className="flex items-center" href={route("home")}>
            <WhrcLogoOnLight className="h-12 hidden md:block" />
            <WhrcLogomarkOnLight className="h-12 md:hidden" />
            <div className={"sr-only"}>
              <div className="text-sm text-cerulean-500 sm:text-base lg:text-lg ml-4">
                {props.sectionTitle}
              </div>
              {props.sectionSubtitle && (
                <div className="text-xs font-light text-cerulean-800 sm:text-xs lg:text-sm ml-4">
                  {props.sectionSubtitle}
                </div>
              )}
            </div>
          </Link>
        </div>
      </div>
      <div className="h-16 md:items-center md:flex md:grow md:bg-charcoal-50">
        <div className="hidden md:flex md:grow">
          <input type="text" />
        </div>
        <div className="mx-2 flex flex-none items-center h-full space-x-2">
          <div className="flex md:hidden">
            <a
              className="cursor-pointer p-1 text-cerulean-500 hover:text-cerulean-600"
              onClick={mobileSearchClickHandler}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </a>
          </div>
          <div className="flex items-center">
            <a
              className="cursor-pointer p-1 text-gray-400 hover:text-gray-500"
              href="https://google.com"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            </a>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Trigger>
                <button
                  type="button"
                  className="flex items-center font-normal rounded-lg bg-whrc-white hover:bg-charcoal-50 px-2 space-x-2 py-1 border border-charcoal-100 shadow"
                >
                  {auth.effectivePartner && (
                    <SimpleAvatar
                      profile={auth.partnerProfile}
                      className={"w-10 h-10 border border-gray-300"}
                    />
                  )}
                  {auth.isWhrcUser && (
                    <WhrcLogomarkOnLight className={"w-10 h-10"} />
                  )}
                  {(auth.profile && (
                    <SimpleAvatar
                      profile={auth.profile}
                      className={"w-10 h-10 border border-gray-300"}
                    />
                  )) || (
                    <div>
                      {auth.user.first_name} {auth.user.last_name}
                    </div>
                  )}
                </button>
              </Dropdown.Trigger>

              <Dropdown.Content>
                <Dropdown.Link href={route("profile.edit")}>
                  User Profile
                </Dropdown.Link>
                <hr />
                {auth.effectivePartner && (
                  <>
                    <Dropdown.Link
                      href={route("partner.index", {
                        partner: auth.effectivePartner.partner.slug,
                      })}
                    >
                      Partner Dashboard
                    </Dropdown.Link>
                    <hr />
                    <Dropdown.Link
                      href={route("partner.me.profile.index", {
                        partner: auth.effectivePartner.partner.slug,
                      })}
                    >
                      Partner User Profile
                    </Dropdown.Link>
                    <Dropdown.Link
                      href={route("partner.settings.index", {
                        partner: auth.effectivePartner.partner.slug,
                      })}
                    >
                      Partner Settings
                    </Dropdown.Link>
                    <hr />
                  </>
                )}
                <Dropdown.Link href={route("logout")} method="post" as="button">
                  Log Out
                </Dropdown.Link>
              </Dropdown.Content>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrimaryHeader
