import { PropsWithChildren } from "react"

import { Button } from "@/Components/Buttons/index"
import { ButtonProps, ButtonVariant } from "@/Components/Buttons/types"

const WhiteOutlinedButton = ({
  ...otherProps
}: PropsWithChildren<ButtonProps>) => {
  return (
    <>
      <Button variant={ButtonVariant.whiteOutlined} {...otherProps} />
    </>
  )
}

export default WhiteOutlinedButton
