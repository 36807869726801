import { ReactNode, useState } from "react"

import SidebarNav, { Item } from "@/Components/Navigation/SidebarNav"
import MobileMenu from "@/Components/Shells/NonClient/MobileMenu"
import MobileSearch from "@/Components/Shells/NonClient/MobileSearch"
import NavigationTransition from "@/Components/Shells/NonClient/NavigationTransition"
import PrimaryHeader from "@/Components/Shells/NonClient/PrimaryHeader"

const NonClientShell = ({
  navigation,
  sectionTitle,
  sectionSubtitle,
  children,
}: {
  children?: ReactNode
  navigation: Array<Item>
  sectionSubtitle?: string
  sectionTitle: string
}) => {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState<boolean>(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)

  const toggleMobileSearchPanel = () => {
    if (!isMobileSearchOpen) {
      setIsMobileMenuOpen(false)
    }

    setIsMobileSearchOpen(!isMobileSearchOpen)
  }

  const toggleMobileMenuPanel = () => {
    if (!isMobileMenuOpen) {
      setIsMobileSearchOpen(false)
    }

    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <div className="shadow-md md:shadow-none">
        <PrimaryHeader
          sectionTitle={sectionTitle}
          sectionSubtitle={sectionSubtitle}
          isMobileMenuOpen={isMobileMenuOpen}
          isMobileSearchOpen={isMobileSearchOpen}
          onMobileSearchClick={toggleMobileSearchPanel}
          onMobileMenuClick={toggleMobileMenuPanel}
        />

        <NavigationTransition show={isMobileMenuOpen}>
          <hr />
          <MobileMenu navigation={navigation} />
        </NavigationTransition>

        <NavigationTransition show={isMobileSearchOpen}>
          <hr />
          <MobileSearch />
        </NavigationTransition>
      </div>

      <div className="relative flex">
        <div className="inset absolute hidden h-full max-h-screen overflow-y-auto md:flex md:h-[calc(100vh-4rem)] md:w-48 lg:w-64">
          <div className="w-full bg-charcoal-50">
            <SidebarNav.Navbar>
              {navigation.map((item) => (
                <SidebarNav.Link key={item.name} item={item} />
              ))}
            </SidebarNav.Navbar>
          </div>
        </div>
        <style>
          .alwaysScroll::-webkit-scrollbar {"{"}
          -webkit-appearance: none; width: 8px;
          {"}"}
          .alwaysScroll::-webkit-scrollbar-thumb {"{"}
          background-color: rgba(0, 0, 0, .125); border-left: 1px solid rgba(0,
          0, 0, .125); border-top: 1px solid rgba(0, 0, 0, .25); border-bottom:
          1px solid rgba(0, 0, 0, .125); border-right: 1px solid rgba(255, 255,
          255, .25);
          {"}"}
        </style>
        <div className="bg-charcoal-50 alwaysScroll h-[calc(100vh-4rem)] w-full md:absolute md:left-[12rem] md:h-[calc(100vh-4rem)] md:w-[calc(100vw-12rem)] md:overflow-auto lg:left-[16rem] lg:w-[calc(100vw-16rem)]">
          <div className={"md:rounded-tl-lg bg-whrc-white min-h-full"}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default NonClientShell
