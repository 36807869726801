import { PropsWithChildren } from "react"

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  notTight?: boolean
}

const ClientFooterContainer = ({
  children,
  className,
  notTight = false,
}: PropsWithChildren<Props & { className?: string }>) => {
  const baseClassName =
    "m-0 mx-auto max-w-full phone:max-w-screen-sm tablet:max-w-screen-desktop desktop:max-w-client-footer"
  const tightClassName = notTight ? "px-4 sm:px-6 xl:px-8" : "p-0"

  return (
    <div className={classNames(baseClassName, tightClassName, className)}>
      {children}
    </div>
  )
}

export default ClientFooterContainer
