import Identicon, { IdenticonOptions } from "identicon.js"
import uuidToHex from "uuid-to-hex"

import {
  AnyProfile,
  ClientProfile,
  PartnerProfile,
  PartnerUserProfile,
} from "@/types"

export const generatePartnerUserAvatarUrl = (
  partnerUserProfile?: PartnerUserProfile,
) => {
  if (!partnerUserProfile) {
    return "https://i.pravatar.cc/150?u=unknown"
  }

  if (!partnerUserProfile?.avatarUrl) {
    return "https://i.pravatar.cc/150?u=" + partnerUserProfile.emailAddress
  }

  return partnerUserProfile.avatarUrl
}

export const generateClientAvatarUrl = (clientProfile?: ClientProfile) => {
  if (!clientProfile) {
    return "https://i.pravatar.cc/150?u=unknown"
  }

  if (!clientProfile?.avatarUrl) {
    return "https://i.pravatar.cc/150?u=" + clientProfile.emailAddress
  }

  return clientProfile.avatarUrl
}
export const generatePartnerLogoUrl = (partnerProfile?: PartnerProfile) => {
  if (partnerProfile?.logoUrl) {
    return partnerProfile.logoUrl
  }

  // const hash = "c157a79031e1c40f85931829bc5fc552" // 15+ hex chars
  const hash = generateHash(partnerProfile?.partnerId || "")
  const options = {
    foreground: [0, 0, 0, 255], // rgba black
    background: [255, 255, 255, 255], // rgba white
    margin: 0.2, // 20% margin
    size: 420, // 420px square
    format: "svg", // use SVG instead of PNG
  }

  // create a base64 encoded SVG
  const data = new Identicon(hash, options as IdenticonOptions).toString()

  return "data:image/svg+xml;base64," + data
}

function generateHash(str: string) {
  return uuidToHex(str)
}

export const generateAvatarUrlForAnyProfile = (profile: AnyProfile) => {
  if ("clientId" in profile) {
    return generateClientAvatarUrl(profile)
  }

  if ("partnerUserId" in profile) {
    return generatePartnerUserAvatarUrl(profile)
  }

  if ("partnerId" in profile) {
    return generatePartnerLogoUrl(profile)
  }

  return "https://i.pravatar.cc/150?u=unknown"
}
