import { PropsWithChildren } from "react"

function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  tight?: boolean
}

const ClientContentContainer = ({
  children,
  className,
  tight = false,
}: PropsWithChildren<Props & { className?: string }>) => {
  const baseClassName =
    "m-0 mx-auto max-w-full phone:max-w-screen-sm tablet:max-w-screen-desktop desktop:max-w-client-content"
  const tightClassName = tight ? "p-0" : "p-4 sm:p-6 xl:p-8"

  return (
    <div className={classNames(baseClassName, tightClassName, className)}>
      {children}
    </div>
  )
}

export default ClientContentContainer
