import {
  HTMLAttributes,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react"

import { InertiaLinkProps, Link } from "@inertiajs/react"

import {
  ButtonAs,
  ButtonImpact,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from "@/Components/Buttons/types"
import DatumQuestionValidation from "@/Components/DatumQuestionValidation/DatumQuestionValidation"

const asClasses = {
  [ButtonAs.link]: "inline-block",
  [ButtonAs.button]: "",
}

const sizeClasses = {
  [ButtonSize.xs]: "rounded px-2.5 py-1.5 text-xs font-normal",
  [ButtonSize.sm]: "rounded px-3 py-2 text-sm font-normal leading-4",
  [ButtonSize.base]: "rounded-md px-4 py-2 text-sm font-normal",
  [ButtonSize.lg]: "rounded-md px-4 py-2 text-sm font-normal",
  [ButtonSize.xl]: "rounded-md px-6 py-3 text-sm font-normal",
}

const variantClasses = {
  [ButtonVariant.primary]:
    "shadow-sm border border-transparent bg-cerulean-600 text-hc-cerulean-600 hover:bg-cerulean-700 hover:text-hc-cerulean-700 focus:ring-cerulean-500",
  [ButtonVariant.secondary]:
    "shadow-sm border border-gray-200 hover:border-gray-300 bg-gray-100 text-hc-gray-100 hover:bg-gray-200 hover:text-hc-gray-200 focus:ring-cerulean-500",
  [ButtonVariant.white]:
    "shadow-sm border border-transparent bg-white text-gray-700 hover:bg-gray-200 hover:text-gray-800 focus:ring-cerulean-500",
  [ButtonVariant.link]:
    "underline decoration-from-font decoration-cerulean-500 hover:decoration-cerulean-600 hover:text-cerulean-800 border border-transparent bg-transparent text-cerulean-700 focus:ring-cerulean-500",
  [ButtonVariant.whiteOutlined]:
    "shadow-sm border border-charcoal-200 text-charcoal-100 hover:border-whrc-pure-white hover:bg-whrc-white hover:bg-opacity-5 hover:text-whrc-white focus:ring-gray-200",
  [ButtonVariant.mediumImpact]:
    "shadow-sm border border-transparent bg-orange-600 text-white hover:bg-orange-700 hover:text-white focus:ring-orange-600",
  [ButtonVariant.severeImpact]:
    "shadow-sm border border-transparent bg-red-700 text-white hover:bg-red-800 hover:text-white focus:ring-red-700",
}

const impactToVariant = {
  [ButtonImpact.normal]: ButtonVariant.primary,
  [ButtonImpact.medium]: ButtonVariant.mediumImpact,
  [ButtonImpact.severe]: ButtonVariant.severeImpact,
}

const asProps = {
  [ButtonAs.link]: { role: "button" },
  [ButtonAs.button]: {},
}

const Button = ({
  withoutInertia = false,
  asLink = undefined,
  actualAs: tag = ButtonAs.button,
  size = ButtonSize.base,
  variant: variantProp,
  impact = ButtonImpact.normal,
  className = "",
  onClick = undefined,
  ...otherProps
}: PropsWithChildren<ButtonProps>) => {
  const [disabledEffect, setDisabledEffect] = useState(false)
  const { validateSubmit, addValidationFailedHandler, generateIdentifier } =
    useContext(DatumQuestionValidation)

  const variant = variantProp ?? impactToVariant[impact]

  const identifier = generateIdentifier()

  useEffect(() =>
    addValidationFailedHandler(identifier, (sourceId) => {
      if (sourceId !== identifier) {
        return
      }

      setDisabledEffect(true)
    }),
  )

  const disabledClassName = disabledEffect
    ? "animate-wiggle bg-failure hover:bg-failure"
    : ""
  const actuallyDisabledClassName =
    "disabled" in otherProps && otherProps.disabled ? " opacity-50 " : ""
  const Tag =
    asLink !== undefined
      ? asLink
        ? "a"
        : "button"
      : tag === ButtonAs.link
      ? "a"
      : "button"
  const computedClassName =
    "transition-all duration-200" +
    asClasses[tag] +
    " " +
    "font-poppins" +
    " " +
    sizeClasses[size] +
    " " +
    variantClasses[variant] +
    " " +
    className +
    " " +
    disabledClassName +
    actuallyDisabledClassName
  const computedProps = asProps[tag]

  const computedOnClick = (
    event: (
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
    ) &
      React.MouseEvent<HTMLOrSVGElement>,
  ) => {
    if (!validateSubmit(identifier)) {
      event.preventDefault()

      return
    }

    if (onClick === undefined) {
      return
    }

    onClick(event)
  }

  const mergedComputedProps = {
    ...computedProps,
    ...otherProps,
    ...(computedOnClick ? { onClick: computedOnClick } : {}),
    onAnimationEnd: () => {
      setDisabledEffect(false)
    },
  }

  if (withoutInertia) {
    const WAT = mergedComputedProps as HTMLAttributes<HTMLOrSVGElement>

    return <Tag className={computedClassName} {...WAT} />
  }

  const WAT2: InertiaLinkProps = mergedComputedProps as InertiaLinkProps

  return <Link as={Tag} className={computedClassName} {...WAT2} />
}

export default Button
