import { PropsWithChildren } from "react"

import MinimalLayout from "@/Layouts/MinimalLayout"

import NarrowContainer from "@/Components/Containers/NarrowContainer"

export default function AuthLayout({ children }: PropsWithChildren) {
  return (
    <MinimalLayout>
      <NarrowContainer>{children}</NarrowContainer>
    </MinimalLayout>
  )
}
