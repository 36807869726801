import { PropsWithChildren } from "react"

import { Item } from "@/Components/Navigation/SidebarNav"
import NonClientShell from "@/Components/Shells/NonClient/NonClientShell"

const navigation: Array<Item> = [
  { name: "Buttons", href: route("_private.design-system.buttons") },
  { name: "Geographic", href: route("_private.design-system.geographic") },
  { name: "Typography", href: route("_private.design-system.typography") },
]
export default function DesignSystemLayout({ children }: PropsWithChildren) {
  return (
    <NonClientShell sectionTitle="Design System" navigation={navigation}>
      {children}
    </NonClientShell>
  )
}
