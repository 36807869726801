import { PropsWithChildren } from "react"

import FillVerticalSpaceContainer from "@/Layouts/Common/FillVerticalSpaceContainer"
import PublicFooter from "@/Layouts/Common/PublicFooter"
import PublicHeader from "@/Layouts/Common/PublicHeader"

export default function MinimalLayout({ children }: PropsWithChildren) {
  return (
    <>
      <PublicHeader />
      <FillVerticalSpaceContainer>{children}</FillVerticalSpaceContainer>
      <PublicFooter />
    </>
  )
}
